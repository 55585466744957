import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { callApiWithResult } from '@tra-sg/gatsby-theme-c360-portal/src/data/backend_api';
import SwaggerUI from "swagger-ui-react"
import "swagger-ui-react/swagger-ui.css"


export default function ApiDocs(props) {
    const { isLoading, isError, data, error } = useQuery(
        ["swagger/", {}],
        async () => await callApiWithResult("swagger/")
    )

    if (isLoading) {
        return (<div>Loading API docs...</div>)
    }

    return (
        <div>
            <SwaggerUI spec={data} />
        </div>
    )
}
