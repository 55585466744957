import React from 'react';
import StatusOverview from '@tra-sg/gatsby-theme-c360-portal/src/components/StatusOverview'
import PageWrapper from '@tra-sg/gatsby-theme-c360-portal/src/components/PageWrapper';
import { Location } from "@reach/router";
import ApiDocs from '@tra-sg/gatsby-theme-c360-portal/src/components/Docs/Api'


export default function StatusDocs({}) {
  return (
    <PageWrapper
      activeTab="status"
      metaTitle="Docs | c360"
    >
      <Location>
        {({ location }) => (
          <div>
            <ApiDocs />
          </div>
        )}
      </Location>
    </PageWrapper>
  )
}
